const push = (obj) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(obj);
}

export const productHash = (args) => {
  return {
    'id': args.id,                    // Product ID (string).
    'name': args.name,                // Product name (string).
    'category': args.category,        // Product category (string).
    'list': args.list,                // Product list (string).
    'position': args.position         // Product position (number).
  };
};

export const trackImpression = (args) => {
  push({
    event: 'eec.impressionView',
    ecommerce: { impressions: [productHash(args)] }
  });
};

export const trackDetailView = (args) => {
  push({
    event: 'gtm.dom',
    ecommerce: { detail: {products: [productHash(args)]} }
  });
};

export const trackClick = (args, redirectTo, evt) => {
  push({
    event: 'eec.impressionClick',
    ecommerce: {
      click: {
        actionField: { list: args.list },
        products: [productHash(args)]
      }
    }
  });
  if (redirectTo) {
    document.location = redirectTo
  } else if (evt) {
    evt.preventDefault();
    const link = evt.target.closest('a').href;
    if (evt.metaKey || evt.ctrlKey) {
      window.open(link);
    } else {
      window.location.assign(evt.target.closest('a').href);
    }
  }
};

export const isInViewport = (element) => {
  if (!element) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const trackPurchase = (args) => {
  push({
    ecommerce: {
      purchase: {
        actionField: {
          id: Date.now().toString(),
          revenue: args.revenue,
          affiliation: args.affiliation,
          coupon: args.coupon
        },
        products: [{
          name: args.name,
          id: args.id,
          quantity: 1
        }]
      }
    }
  });
}
